import React from "react"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import EventData from "../../../content/events/appril/index.json"
import DynamicForm from "../../../components/forms/dynamic-form"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ParagraphBlock from "../../../components/misc/paragraph-block";
import ImageBlock from "../../../components/misc/image-block";
import Layout from "../../../components/layout";

const EventsApprilPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={EventData[intl.locale].title} description={EventData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={EventData[intl.locale]} />
        <div className={`event`}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className={`event-content`}>
                            {EventData[intl.locale].content.map((c, i) => {
                                if (c.type === "image-block") {
                                    return (
                                        <div key={i}>
                                            <ImageBlock content={c} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>
                                            <ParagraphBlock content={c} />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`event-form`}>
                                <DynamicForm type={`eventSubscription`} defaultValues={{eventName: `appril`}} redirect={`/forms/thank-you/`} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
)

export default injectIntl(EventsApprilPage)
